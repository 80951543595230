<template>
  <div class="product-build-info">
    <!-- WLC + Aquarius -->
    <ul v-if="isWLCProduct && isAquariusBranding">
      <li>
        <div v-if="isLoading" class="loading-spinner-wrapper">
          <i class="fa fa-gear animated-gear muted"></i>
        </div>
        <small v-else class="muted">
          {{ appBuildInfoString }}
        </small>
      </li>
    </ul>

    <!-- дефолтное -->
    <ul v-else>
      <li v-if="BUILD_VERSION">
        <small class="muted">
          Version: {{ BUILD_VERSION }}
        </small>
      </li>
      <li v-if="BUILD_DATE">
        <small class="muted">
          Build date: {{ BUILD_DATE }}
        </small>
      </li>
      <li v-if="!isBeelineBranding">
        <small class="muted">
          <a href="http://wimark.com" target="_blank">
            Wimark Systems
          </a>
          <span>
            &copy; {{ new Date().getFullYear() }}
          </span>
        </small>
      </li>
    </ul>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import { BUILD_VERSION, BUILD_DATE } from '@/config';

import appBuildInfoApi from './api';

const { mapGetters: WLCProductMapGetters } = createNamespacedHelpers('WLCProduct');
const { mapGetters: brandingMapGetters } = createNamespacedHelpers('branding');

export default {
  name: 'ProductBuildInfo',
  props: {
  },
  data() {
    return {
      isLoading: false,
      appBuildInfo: {}
    };
  },
  computed: {
    ...WLCProductMapGetters(['isWLCProduct']),
    ...brandingMapGetters(['isBeelineBranding', 'isAquariusBranding']),
    appBuildInfoString() {
      const {
        versionName,
        versionId,
        versionNumber,
        commitTime,
        commitAuthor,
        versionPrefix
      } = this.appBuildInfo;

      return `${versionPrefix}, version ${versionId}.${versionNumber}`;
    }
  },
  watch: {
  },
  methods: {
    getAppBuildInfo() {
      appBuildInfoApi.getAppBuildInfo({
        onLoading: () => {
          this.isLoading = true;
        },
        onSuccess: (appBuildInfo) => {
          this.isLoading = false;
          this.appBuildInfo = appBuildInfo;
        },
        onError: (error) => {
          this.isLoading = false;
        }
      });
    }
  },
  created() {
    this.BUILD_VERSION = BUILD_VERSION;
    this.BUILD_DATE = BUILD_DATE;
    this.getAppBuildInfo();
  },
  mounted() {
  }
};
</script>

<style lang="css" scoped>
ul {
  margin: 0;
}
.product-build-info {
  display: flex;
  flex-direction: column;
  padding: 0 8px;
}

.muted {
  color: var(--gray-light);
}
.loading-spinner-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
.loading-spinner {
  width: 18px;
  height: 18px;
}

.animated-gear {
  animation: rotateGear 2s linear infinite;
}

@keyframes rotateGear {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
