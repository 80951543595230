import { v4 as uuidv4 } from 'uuid';
import RouteGroups from '@/access-control/RouteGroups';

function showMainSectionsAdministrationGeneral({ thisInstance, $can }) {
  // todo refactor
  // осталось после рефакторинга в таком виде, в каком есть
  // не понятно, что здесь написано вообще
  try {
    if (thisInstance.$canSomeInGroup('read', [...RouteGroups.ADMINISTRATION])
          && !thisInstance.isPortalOnlyModeEnabled) {
      if (($can('read', { route: 'SNMP' }))
            || ($can('read', { route: 'ConfigExportImport' }) && !thisInstance.isOperatorRole)) {
        return true;
      }
    }
    return false;
  } catch (e) {
    return true;
  }
}

/**
 * Описание ролей:
 * роли нужны для построения иерархичности + для специфичного отображения элементов в соответствии с ролью.
 *
 * root - это начало категории. Может быть ссылкой без вложенных элементов, так и не ссылкой с вложенными элементами.
 * section - подраздел. Не является ссылкой. Имеет вложенные элементы children, которые являются ссылкой (обычно role: link). Используется как группировка раздела ссылок и отображается как заголовок.
 * link - конечный элемент пути - ссылка. Не имеет children
 * @param {'root' | 'section' | 'link'} role
 */

const getAdministration = ({ $t, thisInstance, $can }) => (
  {
    id: uuidv4(),
    name: 'administration',
    title: $t('sidebarNew.administration'),
    icon: 'fa fa-gear fa-fw fa-lg',
    to: false,
    selected: false,
    role: 'root',
    isShow: showMainSectionsAdministrationGeneral({ thisInstance, $can }),
    children: [
      {
        id: uuidv4(),
        name: 'snmpGeneral',
        title: $t('sidebarNew.SNMP'),
        icon: '',
        to: '/administration/snmp/general',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'SNMP' }) && !thisInstance.isWLCProduct,
        children: []
      },
      {
        id: uuidv4(),
        name: 'ConfigExportImport',
        title: $t('sidebarNew.ConfigExportImport'),
        icon: '',
        to: '/config_export_import',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'ConfigExportImport' }) && !thisInstance.isOperatorRole && !thisInstance.isWLCProduct,
        children: []
      },
      {
        id: uuidv4(),
        name: 'time',
        title: $t('sidebarNew.Time'),
        icon: '',
        to: '/administration/time',
        selected: false,
        role: 'link',
        isShow: $can('read', { route: 'Time' }),
        children: []
      }
    ]
  }
);

export default getAdministration;
