/**
 * API-модуль для инфо о сборке.
 * @module appBuildInfoApi
*/

import getAppBuildInfo from './methods/getAppBuildInfo';

const appBuildInfoApi = {
  getAppBuildInfo
};

export default appBuildInfoApi;
