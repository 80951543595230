/* eslint-disable camelcase */
/* eslint-disable consistent-return */

import store from '@/store';
import { AXIOS_INSTANCE, GET_APP_BUILD_INFO_URL } from '../config';

// swagger https://gitlab.wimark.com/backend/grpc-contracts/-/blob/master/gen/go/gproxy_service/service/v1/gproxy.swagger.json?ref_type=heads
/**
 * @typedef {Object} ResponseData
 * @property {string} versionName
 * @property {string} versionId
 * @property {string} versionNumber
 * @property {string} commitTime
 * @property {string} commitAuthor
 * @property {string} versionPrefix
 */

/**
 * Функция-фетчер для получения app build info.
 * @returns {Promise<ResponseData>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */
const getAppBuildInfoFetcher = async () => {
  try {
    const response = await AXIOS_INSTANCE.get(GET_APP_BUILD_INFO_URL);

    const { data } = response;

    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * фукнция-обработчик фетчера getAppBuildInfoFetcher.
 * выполняет побочные действия и обрабатывает тело запроса.
 *
 * @param {Object} [options] - Дополнительные опции запроса.
 * @param {Function} [options.onLoading] - Callback при начале выполнения запроса.
 * @param {Function} [options.onSuccess] - Callback при успешном выполнении запроса.
 * @param {Function} [options.onError] - Callback при ошибке выполнения запроса.
 * @returns {Promise<ResponseData>} - Обещание с данными ответа.
 * @throws {Error} - Ошибка при выполнении запроса.
 */

const getAppBuildInfo = async (options = {}) => {
  const {
    onLoading,
    onSuccess,
    onError
  } = options;

  try {
    if (onLoading) {
      onLoading();
    }

    store.commit('setLoading');

    const responseData = await getAppBuildInfoFetcher();

    store.commit('setSuccess');
    onSuccess(responseData);
    return Promise.resolve(responseData);
  } catch (error) {
    if (onError) {
      onError(error);
    }
    store.commit('setError', error);
  }
};

export default getAppBuildInfo;
